import styled, { css } from 'styled-components'
import * as types from './types'
import borders from '../theme/borders'
import breakpoint from '../theme/breakpoint'
import color from '../theme/color'
import space from '../theme/space'
import { CheckboxStyleProps } from '../Checkbox/types'

export const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

export const IconWrapper = styled.span<CheckboxStyleProps>`
  position: relative;
  justify-content: center;
  align-items: center;
  width: 1.7rem;
  min-width: 1.7rem;
  height: 1.7rem;
  margin-right: ${space.xs};
  // left: -${space.xs};
  left: 0;

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  .inner-shape-class {
    border-color: ${(p) => (p.checked ? 'black' : 'white')};
  }

  .shape-class {
    border-color: ${(p) => (p.checked ? color.grayscale.primary : color.grayscale.gray)};
    background-color: white;
  }

  @media (min-width: ${breakpoint.lg}) {
    margin-right: ${space.md};
  }
`

export const Infos = styled.span<{ orientation: string; checkedStatus: boolean }>`
  ${(props) =>
    props.orientation === 'vertical'
      ? `
    position: relative;
    left:  auto;
    top: auto;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    `
      : `
        display: flex;
        flex-wrap: wrap;
    `}
`

export const Hint = styled.sup`
  margin-left: ${space.xs2};
  cursor: help;
`

const getCheckedStyle = (checked: boolean, backgroundType: types.BackgroundType, orientation: string) => {
  if (checked) {
    return css`
      background-color: ${backgroundType === 'white' ? color.grayscale.white : color.grayscale.lighter};
      border: ${borders.sizes.md} solid ${color.primary.darker};
      padding: 0 // ${orientation === 'vertical' ? `padding: 0` : `padding: ${space.sm} 1.25rem;`}
        svg {
        fill: ${color.primary.darker};
      }
    `
  }

  return css`
    background-color: ${color.grayscale.white};
    // padding: ${space.sm} 1.25rem;
    padding: 0;
    border: ${borders.sizes.md} solid ${color.grayscale.light};

    svg {
      fill: ${color.grayscale.dark};
    }
  `
}

const cssDisabled = css`
  opacity: 0.4;
`

const getOrientation: types.GetOrientationProps = {
  horizontal: css`
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  `,
  vertical: css`
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    ${IconWrapper} {
      width: 100%;
      height: 100%;
      min-height: 8rem;
      padding-top: 1rem;
      padding-left: 1rem;
    }
  `
}

export const SelectCard = styled.label<types.SelectCardStyleProps>`
  position: relative;
  background: ${(p) => (p.giveBackground ? `url(${p.giveBackground})` : '#ffff')};
  background-repeat: no-repeat;
  background-position: 100% -1.75rem;
  display: flex;
  align-self: stretch;
  width: 9rem;
  height: auto;
  min-height: 1rem;
  border-radius: ${borders.radius.lg};
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex-wrap: wrap;
  ${({ checked, backgroundType, orientation }) => getCheckedStyle(checked, backgroundType, orientation)};
  ${({ disabled }) => disabled && cssDisabled};
  ${({ orientation }) => getOrientation[orientation]};

  .topHint {
    left: 6rem;
  }

  @media (min-width: ${breakpoint.lg}) {
    width: 13rem;
    ${({ orientation }) => orientation === 'vertical' && 'width: 10rem'};
  }
`
export const PriceInfo = styled.div`
  border-top: 1px solid ${color.grayscale.gray};
  width: 100%;
  padding: ${space.xs} ${space.xl};
`
export const ActionPart = styled.div<{ orientation: string }>`
  width: 100%;
  display: flex;
  padding: ${space.sm} 1.25rem;
  ${(props) => (props.orientation === 'vertical' ? `flex-direction: column;` : `flex-direction: row;`)}
  ${(props) => (props.orientation === 'vertical' ? `padding: 0;` : `padding: ${space.sm} 1.25rem;`)}
`

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { parseCookies } from 'nookies'
import { StorageKey, Cookies, ICartSelection } from '@/client/types'
import {
  getCartItems,
  updateCart,
  addCartItem,
  updateCartItem,
  deleteCartItem,
  checkoutCart,
  addQuoteItemToCart,
  replaceCartItem
} from '@/infra/services'
import { IMutateUpdateCart, IMutateUpdateCartErrorResponse, IMutateUpdateCartItem } from './types'

export const useGetCartItems = () => {
  const cookies = parseCookies()
  const cartId = cookies[Cookies.CART_ID]
  const { data, isLoading, isFetching, refetch, isRefetching, remove } = useQuery({
    queryKey: [StorageKey.GET_CART],
    queryFn: () => getCartItems(cartId),
    enabled: !!cartId
  })

  if (!cartId) {
    return {
      isLoading,
      isFetching,
      refetch,
      isRefetching,
      remove,
      data: {
        cart_items: [],
        quantity_items: 0
      }
    }
  }
  return {
    isLoading,
    isFetching,
    refetch,
    isRefetching,
    remove,
    data: {
      ...data,
      quantity_items: data?.cart_items?.length
    }
  }
}

export const useMutateUpdateCart = () => {
  const cookies = parseCookies()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ payload }: IMutateUpdateCart) => updateCart(cookies[Cookies.CART_ID], payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.GET_CART] })
    },
    onError: (error: IMutateUpdateCartErrorResponse) => error
  })
}

export const useMutateAddCartItem = () => {
  const queryClient = useQueryClient()
  const cookies = parseCookies()

  return useMutation({
    mutationFn: (item: ICartSelection) => addCartItem(item, cookies[Cookies.CART_ID]),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.GET_CART] })
    }
  })
}

export const useMutateAddQuoteItem = () => {
  const queryClient = useQueryClient()
  const cookies = parseCookies()

  return useMutation({
    mutationFn: (quoteItem: string) => addQuoteItemToCart(quoteItem, cookies[Cookies.CART_ID]),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.GET_CART] })
    }
  })
}

export const useMutateUpdateCartItem = () => {
  const queryClient = useQueryClient()
  const cookies = parseCookies()

  return useMutation({
    mutationFn: ({ cartItemId, payload }: IMutateUpdateCartItem) =>
      updateCartItem(cookies[Cookies.CART_ID], cartItemId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.GET_CART] })
    }
  })
}

export const useMutateReplaceCartItem = () => {
  const queryClient = useQueryClient()
  const cookies = parseCookies()

  return useMutation({
    mutationFn: ({ cartItemId, payload }: IMutateUpdateCartItem) =>
      replaceCartItem(cookies[Cookies.CART_ID], cartItemId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.GET_CART] })
    }
  })
}

export const useAddQuoteItem = () => {
  const queryClient = useQueryClient()
  const cookies = parseCookies()

  return useMutation({
    mutationFn: (quoteItem: string) => addQuoteItemToCart(quoteItem, cookies[Cookies.CART_ID]),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.GET_CART] })
    }
  })
}

export const useMutateDeleteCartItem = () => {
  const queryClient = useQueryClient()
  const cookies = parseCookies()

  return useMutation({
    mutationFn: (cartItemId: string) => deleteCartItem(cookies[Cookies.CART_ID], cartItemId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.GET_CART] })
    }
  })
}

export const useGetCheckoutCart = () => {
  const cookies = parseCookies()
  const { data, refetch, isError, isLoading, isInitialLoading, isSuccess } = useQuery({
    queryKey: [StorageKey.CHECKOUT_CART],
    queryFn: () => checkoutCart(cookies[Cookies.CART_ID]),
    enabled: false
  })

  return {
    isLoading,
    refetch,
    isError,
    isInitialLoading,
    redirect_url: data?.redirect_url,
    isSuccess
  }
}

/*
 *
 * DS => Design Serive
 *
 *
 * */
export enum LABELLIBRARY_ACTIONS {
  ADD_TO_COPY_SELECTION = 'ADD_TO_COPY_SELECTION',
  ADD_TO_FAV_SELECTION = 'ADD_TO_FAV_SELECTION',
  SET_CURRENT_FOLDER = 'SET_CURRENT_FOLDER',
  LABELLIBRARY_ACTIONS = 'LABELLIBRARY_ACTIONS',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  SET_TOTAL_PAGES = 'SET_TOTAL_PAGES',
  SET_LOADER = 'SET_LOADER',
  SET_MESSAGES = 'SET_MESSAGES',
  SET_UPLOAD_MODAL = 'SET_UPLOAD_MODAL',
  SET_CHANGES_ON_FILE = 'SET_CHANGES_ON_FILE',
  SET_CHANGES_ON_FOLRDERS = 'SET_CHANGES_ON_FOLRDERS',
  SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD',
  SET_CURRENT_FOLDER_NAME = 'SET_CURRENT_FOLDER_NAME',
  SET_JOB_ID = 'SET_JOB_ID',
  SET_PER_PAGE = 'SET_PER_PAGE',
  SET_CURRENT_SORT = 'SET_CURRENT_SORT'
}

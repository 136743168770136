import { IRoutes, ILanguages } from './types'

export const ROUTES: Record<IRoutes, ILanguages> = {
  [IRoutes.CALCULATOR]: {
    es: 'calculadora',
    en: 'calculator',
    it: 'calculator',
    au: 'calculator'
  },
  [IRoutes.HOME]: {
    es: 'inicio',
    en: 'home',
    it: 'inicio',
    au: 'inicio'
  },
  [IRoutes.ABOUT_US]: {
    es: 'sobre-nosotros',
    en: 'about-us',
    it: 'sobre-nosotros',
    au: 'sobre-nosotros'
  },
  [IRoutes.LOGIN]: {
    es: 'acceso',
    en: 'login',
    it: 'acceso',
    au: 'acceso'
  },
  [IRoutes.SIGNUP]: {
    es: 'inscribirse',
    en: 'signup',
    it: 'inscribirse',
    au: 'inscribirse'
  },
  [IRoutes.FORGOT_PASSWORD]: {
    es: 'se-te-olvido-tu-contrasena',
    en: 'forgot-password',
    it: 'se-te-olvido-tu-contrasena',
    au: 'se-te-olvido-tu-contrasena'
  },
  [IRoutes.PRIVACY_POLICY]: {
    es: 'politica-de-privacidad',
    en: 'privacy-policy',
    it: 'politica-de-privacidad',
    au: 'politica-de-privacidad'
  },
  [IRoutes.CONTACT]: {
    es: 'contacto',
    en: 'contact',
    it: 'contacto',
    au: 'contacto'
  },
  [IRoutes.GLOSSARY]: {
    es: 'glosario',
    en: 'glossary',
    it: 'glosario',
    au: 'glosario'
  },
  [IRoutes.TUTORIALS]: {
    es: 'tutoriales',
    en: 'tutorials',
    it: 'tutoriales',
    au: 'tutoriales'
  },
  [IRoutes.JOBS]: {
    es: 'empleos',
    en: 'jobs',
    it: 'empleos',
    au: 'empleos'
  },
  [IRoutes.FAQ]: {
    es: 'preguntas-mas-frecuentes',
    en: 'customer-service-faq',
    it: 'preguntas-mas-frecuentes',
    au: 'preguntas-mas-frecuentes'
  },
  [IRoutes.MATERIALS]: {
    es: 'materiales',
    en: 'materials',
    it: 'materiales',
    au: 'materiales'
  },
  [IRoutes.EXPRESS_PRODUCTION]: {
    es: 'produccion-expresa-v2',
    en: 'produccion-expresa-v2',
    it: 'produccion-expresa-v2',
    au: 'produccion-expresa-v2'
  },
  [IRoutes.DELIVERY_SHIPPING]: {
    es: 'gastos-de-envio-y-entrega',
    en: 'delivery-and-shipping-costs',
    it: 'gastos-de-envio-y-entrega',
    au: 'gastos-de-envio-y-entrega'
  },
  [IRoutes.FINISHING_TOUCHES]: {
    es: 'acabados-finales',
    en: 'finishing-touches',
    it: 'acabados-finales',
    au: 'acabados-finales'
  },
  [IRoutes.REFERENCE_CUSTOMERS]: {
    es: 'clientes',
    en: 'reference-customers',
    it: 'clientes',
    au: 'clientes'
  },
  [IRoutes.CUSTOMERS_STORIES]: {
    es: 'historias-de-clientes',
    en: 'customer-stories',
    it: 'historias-de-clientes',
    au: 'historias-de-clientes'
  },
  [IRoutes.NEWS]: {
    es: 'noticias',
    en: 'news',
    it: 'noticias',
    au: 'noticias'
  },
  [IRoutes.PRESS]: {
    es: 'prensa',
    en: 'press',
    it: 'prensa',
    au: 'prensa'
  },
  [IRoutes.LABEL_GALLERY]: {
    es: 'galeria-de-etiquetas',
    en: 'label-gallery',
    it: 'galeria-de-etiquetas',
    au: 'galeria-de-etiquetas'
  },
  [IRoutes.CATEGORIES]: {
    es: 'categorias',
    en: 'categories',
    it: 'categorias',
    au: 'categorias'
  },
  [IRoutes.PRODUCTS]: {
    es: 'productos',
    en: 'products',
    it: 'productos',
    au: 'productos'
  },
  [IRoutes.PROOFING_SERVICE]: {
    es: 'prueba-de-impresion',
    en: 'proofing-service',
    it: 'prueba-de-impresion',
    au: 'prueba-de-impresion'
  },
  [IRoutes.ARTWORK_SERVICES]: {
    es: 'servicios-de-revision',
    en: 'artwork-services',
    it: 'servicios-de-revision',
    au: 'servicios-de-revision'
  },
  [IRoutes.PRINT_DATA_DELIVERY]: {
    es: 'entrega-de-archivos-de-impresion',
    en: 'print-data-delivery',
    it: 'entrega-de-archivos-de-impresion',
    au: 'entrega-de-archivos-de-impresion'
  },
  [IRoutes.SAMPLE_BOOK]: {
    es: 'muestrario',
    en: 'sample-book',
    it: 'muestrario',
    au: 'muestrario'
  },
  [IRoutes.WORLD_OF]: {
    es: 'mundo-de',
    en: 'world-of',
    it: 'mundo-de',
    au: 'mundo-de'
  },
  [IRoutes.ARTWORK_ASSISTANT]: {
    es: 'crea-tu-archivo',
    en: 'artwork-assistant',
    it: 'crea-tu-archivo',
    au: 'crea-tu-archivo'
  },
  [IRoutes.DESIGN_SERVICE]: {
    es: 'servicios-de-diseno',
    en: 'design-service',
    it: 'servicios-de-diseno',
    au: 'servicios-de-diseno'
  },
  [IRoutes.SIGNUP_SUCCESS]: {
    es: 'registro-completo',
    en: 'signup-success',
    it: 'registro-completo',
    au: 'registro-completo'
  },
  [IRoutes.TRANSPARENCY_CALCULATOR]: {
    es: 'calculadora-transparency',
    en: 'transparency-calculator',
    it: 'calculadora-transparency',
    au: 'calculadora-transparency'
  },
  [IRoutes.CART]: {
    es: 'cesta',
    en: 'cart',
    it: 'cesta',
    au: 'cesta'
  },
  [IRoutes.LEGAL_WARNING]: {
    es: 'aviso-legal',
    en: 'legal-warning',
    it: 'aviso-legal',
    au: 'aviso-legal'
  },
  [IRoutes.CONDITIONS_OF_SALE]: {
    es: 'condiciones-generales-de-venta',
    en: 'general-conditions-of-sale',
    it: 'condiciones-generales-de-venta',
    au: 'condiciones-generales-de-venta'
  },
  [IRoutes.PAGE_NOT_FOUND]: {
    es: 'pagina-no-encontrada',
    en: 'page-not-found',
    it: 'pagina-no-encontrada',
    au: 'pagina-no-encontrada'
  },
  [IRoutes.PRE_PRINT_ARTWORK]: {
    es: 'pre-print-artwork',
    en: 'pre-print-artwork',
    it: 'pre-print-artwork',
    au: 'pre-print-artwork'
  },
  [IRoutes.UPLOAD_FILES]: {
    es: 'upload-files',
    en: 'upload-files',
    it: 'upload-files',
    au: 'upload-files'
  },
  [IRoutes.MY_ACCOUNT]: {
    es: 'mi-cuenta',
    en: 'my-account',
    it: 'mi-cuenta',
    au: 'mi-cuenta'
  },
  [IRoutes.MANAGE_CREDENTIALS]: {
    es: 'administrar-credenciales',
    en: 'manage-credentials',
    it: 'administrar-credenciales',
    au: 'administrar-credenciales'
  },
  [IRoutes.PROOF_ORDER]: {
    es: 'prueba-de-pedido',
    en: 'proof-order',
    it: 'prueba-de-pedido',
    au: 'prueba-de-pedido'
  },
  [IRoutes.CUSTOMER_REGISTERED]: {
    es: 'cliente-registrado',
    en: 'customer-registered',
    it: 'cliente-registrado',
    au: 'cliente-registrado'
  },
  [IRoutes.MY_ORDERS]: {
    es: 'mi-cuenta/ordenes',
    en: 'my-account/orders',
    it: 'mi-cuenta/ordenes',
    au: 'mi-cuenta/ordenes'
  },
  [IRoutes.ARTWORK_SERVICES_REVIEW_INFO]: {
    es: 'artwork-service-revision',
    en: 'artwork-service-revision',
    it: 'artwork-service-revision',
    au: 'artwork-service-revision'
  },
  [IRoutes.ERROR_PAGE]: {
    es: 'pagina-de-errores',
    en: 'pagina-de-errores',
    it: 'pagina-de-errores',
    au: 'pagina-de-errores'
  },
  [IRoutes.SUSTAINABILITY]: {
    es: 'sostenibilidad',
    en: 'sustainability',
    it: 'sostenibilidad',
    au: 'sostenibilidad'
  },
  [IRoutes.ORDER_ARTWORK_SERVICE]: {
    es: 'encargar-servicios-de-revision',
    en: 'order-artwork-service',
    it: 'encargar-servicios-de-revision',
    au: 'encargar-servicios-de-revision'
  },
  [IRoutes.SAMPLE_SEARCH]: {
    es: 'muestra-de-busqueda',
    en: 'sample-search',
    it: 'muestra-de-busqueda',
    au: 'muestra-de-busqueda'
  },
  [IRoutes.LABEL_LIBRARY]: {
    es: 'label-library',
    en: 'label-library',
    it: 'label-library',
    au: 'label-library'
  },

  [IRoutes.PERSONAL_INFOS]: {
    es: '/mi-cuenta/informacion-personal',
    en: '/my-account/personal-infos',
    it: '/mi-cuenta/informacion-personal',
    au: '/mi-cuenta/informacion-personal'
  },

  [IRoutes.CALCULATOR_HELP]: {
    es: '/calculator-help',
    en: '/calculator-help',
    it: '/calculator-help',
    au: '/calculator-help'
  }
}

const createRouteList = (routes: Object) => {
  const list = {}

  Object.values(routes).forEach((item) => {
    list[`/${item.es}`] = `/${item.en}`
  })

  return list
}

export const routeList = createRouteList(ROUTES)

export const ROUTER_PATH: Record<IRoutes, ILanguages> = {
  [IRoutes.CALCULATOR]: {
    es: '/calculadora',
    en: '/calculator',
    it: '/calculator',
    au: '/calculator'
  },
  [IRoutes.HOME]: {
    es: '/',
    en: '/',
    it: '/',
    au: '/'
  },
  [IRoutes.ABOUT_US]: {
    es: '/sobre-nosotros',
    en: '/about-us',
    it: '/sobre-nosotros',
    au: '/sobre-nosotros'
  },
  [IRoutes.LOGIN]: {
    es: '/acceso',
    en: '/login',
    it: '/acceso',
    au: '/acceso'
  },
  [IRoutes.SIGNUP]: {
    es: '/inscribirse',
    en: '/signup',
    it: '/inscribirse',
    au: '/inscribirse'
  },
  [IRoutes.FORGOT_PASSWORD]: {
    es: '/se-te-olvido-tu-contrasena',
    en: '/forgot-password',
    it: '/se-te-olvido-tu-contrasena',
    au: '/se-te-olvido-tu-contrasena'
  },
  [IRoutes.PRIVACY_POLICY]: {
    es: '/politica-de-privacidad',
    en: '/privacy-policy',
    it: '/politica-de-privacidad',
    au: '/politica-de-privacidad'
  },
  [IRoutes.CONTACT]: {
    es: '/contacto',
    en: '/contact',
    it: '/contacto',
    au: '/contacto'
  },
  [IRoutes.GLOSSARY]: {
    es: '/glosario',
    en: '/glossary',
    it: '/glosario',
    au: '/glosario'
  },
  [IRoutes.TUTORIALS]: {
    es: '/tutoriales',
    en: '/tutorials',
    it: '/tutoriales',
    au: '/tutoriales'
  },
  [IRoutes.JOBS]: {
    es: '/empleos',
    en: '/jobs',
    it: '/empleos',
    au: '/empleos'
  },
  [IRoutes.FAQ]: {
    es: '/preguntas-mas-frecuentes',
    en: '/customer-service-faq',
    it: '/preguntas-mas-frecuentes',
    au: '/preguntas-mas-frecuentes'
  },
  [IRoutes.MATERIALS]: {
    es: '/materiales',
    en: '/materials',
    it: '/materiales',
    au: '/materiales'
  },
  [IRoutes.EXPRESS_PRODUCTION]: {
    es: '/produccion-expresa',
    en: '/express-production',
    it: '/produccion-expresa',
    au: '/produccion-expresa'
  },
  [IRoutes.DELIVERY_SHIPPING]: {
    es: '/gastos-de-envio-y-entrega',
    en: '/delivery-and-shipping-costs',
    it: '/gastos-de-envio-y-entrega',
    au: '/gastos-de-envio-y-entrega'
  },
  [IRoutes.FINISHING_TOUCHES]: {
    es: '/acabados-finales',
    en: '/finishing-touches',
    it: '/acabados-finales',
    au: '/acabados-finales'
  },
  [IRoutes.REFERENCE_CUSTOMERS]: {
    es: '/clientes',
    en: '/reference-customers',
    it: '/clientes',
    au: '/clientes'
  },
  [IRoutes.CUSTOMERS_STORIES]: {
    es: '/historias-de-clientes',
    en: '/customer-stories',
    it: '/historias-de-clientes',
    au: '/historias-de-clientes'
  },
  [IRoutes.NEWS]: {
    es: '/noticias',
    en: '/news',
    it: '/noticias',
    au: '/noticias'
  },
  [IRoutes.PRESS]: {
    es: '/prensa',
    en: '/press',
    it: '/prensa',
    au: '/prensa'
  },
  [IRoutes.LABEL_GALLERY]: {
    es: '/galeria-de-etiquetas',
    en: '/label-gallery',
    it: '/galeria-de-etiquetas',
    au: '/galeria-de-etiquetas'
  },
  [IRoutes.CATEGORIES]: {
    es: '/categorias',
    en: '/categories',
    it: '/categorias',
    au: '/categorias'
  },
  [IRoutes.PRODUCTS]: {
    es: '/productos',
    en: '/products',
    it: '/productos',
    au: '/productos'
  },
  [IRoutes.PROOFING_SERVICE]: {
    es: '/prueba-de-impresion',
    en: '/proofing-service',
    it: '/prueba-de-impresion',
    au: '/prueba-de-impresion'
  },
  [IRoutes.ARTWORK_SERVICES]: {
    es: '/servicios-de-revision',
    en: '/artwork-services',
    it: '/servicios-de-revision',
    au: '/servicios-de-revision'
  },
  [IRoutes.PRINT_DATA_DELIVERY]: {
    es: '/entrega-de-archivos-de-impresion',
    en: '/print-data-delivery',
    it: '/entrega-de-archivos-de-impresion',
    au: '/entrega-de-archivos-de-impresion'
  },
  [IRoutes.SAMPLE_BOOK]: {
    es: '/muestrario',
    en: '/sample-book',
    it: '/muestrario',
    au: '/muestrario'
  },
  [IRoutes.WORLD_OF]: {
    es: '/mundo-de',
    en: '/world-of',
    it: '/mundo-de',
    au: '/mundo-de'
  },
  [IRoutes.ARTWORK_ASSISTANT]: {
    es: '/crea-tu-archivo',
    en: '/artwork-assistant',
    it: '/crea-tu-archivo',
    au: '/crea-tu-archivo'
  },
  [IRoutes.DESIGN_SERVICE]: {
    es: '/servicios-de-diseno',
    en: '/design-service',
    it: '/servicios-de-diseno',
    au: '/servicios-de-diseno'
  },
  [IRoutes.SIGNUP_SUCCESS]: {
    es: '/registro-completo',
    en: '/signup-success',
    it: '/registro-completo',
    au: '/registro-completo'
  },
  [IRoutes.TRANSPARENCY_CALCULATOR]: {
    es: '/calculadora-transparency',
    en: '/transparency-calculator',
    it: '/calculadora-transparency',
    au: '/calculadora-transparency'
  },
  [IRoutes.CART]: {
    es: '/cesta',
    en: '/cart',
    it: '/cesta',
    au: '/cesta'
  },
  [IRoutes.LEGAL_WARNING]: {
    es: '/aviso-legal',
    en: '/legal-warning',
    it: '/aviso-legal',
    au: '/aviso-legal'
  },
  [IRoutes.CONDITIONS_OF_SALE]: {
    es: '/condiciones-generales-de-venta',
    en: '/general-conditions-of-sale',
    it: '/condiciones-generales-de-venta',
    au: '/condiciones-generales-de-venta'
  },
  [IRoutes.PAGE_NOT_FOUND]: {
    es: '/pagina-no-encontrada',
    en: '/page-not-found',
    it: '/pagina-no-encontrada',
    au: '/pagina-no-encontrada'
  },
  [IRoutes.PRE_PRINT_ARTWORK]: {
    es: '/pre-print-artwork',
    en: '/pre-print-artwork',
    it: '/pre-print-artwork',
    au: '/pre-print-artwork'
  },
  [IRoutes.UPLOAD_FILES]: {
    es: '/upload-files',
    en: '/upload-files',
    it: '/upload-files',
    au: '/upload-files'
  },
  [IRoutes.MY_ACCOUNT]: {
    es: '/mi-cuenta',
    en: '/my-account',
    it: '/mi-cuenta',
    au: '/mi-cuenta'
  },
  [IRoutes.MY_ORDERS]: {
    es: '/mi-cuenta/ordenes',
    en: '/my-account/orders',
    it: '/mi-cuenta/ordenes',
    au: '/mi-cuenta/ordenes'
  },
  [IRoutes.MANAGE_CREDENTIALS]: {
    es: 'administrar-credenciales',
    en: 'manage-credentials',
    it: 'administrar-credenciales',
    au: 'administrar-credenciales'
  },
  [IRoutes.PROOF_ORDER]: {
    es: '/prueba-de-pedido',
    en: '/proof-order',
    it: '/prueba-de-pedido',
    au: '/prueba-de-pedido'
  },
  [IRoutes.CUSTOMER_REGISTERED]: {
    es: '/cliente-registrado',
    en: '/customer-registered',
    it: '/cliente-registrado',
    au: '/cliente-registrado'
  },
  [IRoutes.ARTWORK_SERVICES_REVIEW_INFO]: {
    es: '/artwork-service-revision',
    en: '/artwork-service-revision',
    it: '/artwork-service-revision',
    au: '/artwork-service-revision'
  },
  [IRoutes.ERROR_PAGE]: {
    es: '/error-page',
    en: '/error-page',
    it: '/error-page',
    au: '/error-page'
  },
  [IRoutes.SUSTAINABILITY]: {
    es: '/sostenibilidad',
    en: '/sustainability',
    it: '/sostenibilidad',
    au: '/sostenibilidad'
  },
  [IRoutes.ORDER_ARTWORK_SERVICE]: {
    es: '/encargar-servicios-de-revision',
    en: '/order-artwork-service',
    it: '/encargar-servicios-de-revision',
    au: '/encargar-servicios-de-revision'
  },
  [IRoutes.SAMPLE_SEARCH]: {
    es: '/muestra-de-busqueda',
    en: '/sample-search',
    it: '/muestra-de-busqueda',
    au: '/muestra-de-busqueda'
  },
  [IRoutes.LABEL_LIBRARY]: {
    es: '/label-library',
    en: '/label-library',
    it: '/label-library',
    au: '/label-library'
  },
  [IRoutes.PERSONAL_INFOS]: {
    es: '/mi-cuenta/informacion-personal',
    en: '/my-account/personal-infos',
    it: '/mi-cuenta/informacion-personal',
    au: '/mi-cuenta/informacion-personal'
  },
  [IRoutes.CALCULATOR_HELP]: {
    es: '/calculator-help',
    en: '/calculator-help',
    it: '/calculator-help',
    au: '/calculator-help'
  }
}

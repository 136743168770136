import { useQuery } from '@tanstack/react-query'
import { StorageKey } from '@/client/types'
import { getMaterial, getPlusMaterial, getStandardMaterial, getSustainableMaterial } from '@/infra/services/material'

export const useGetMaterial = (id: any = 0, locale: string = 'es') =>
  useQuery([StorageKey.MATERIAL, id, locale], {
    queryFn: () => getMaterial(id, locale),
    enabled: !!id,
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })

export const useGetPlusMaterial = () =>
  useQuery([StorageKey.PLUS_MATERIAL], {
    queryFn: () => getPlusMaterial(),
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })

export const useGetStandardMaterial = () =>
  useQuery([StorageKey.STANDARD_MATERIAL], {
    queryFn: () => getStandardMaterial(),
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })

export const useGetSustainableMaterial = () =>
  useQuery([StorageKey.SUSTAINABLE_MATERIAL], {
    queryFn: () => getSustainableMaterial(),
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })

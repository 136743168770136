import React, { FC, memo, useEffect, useState, useCallback } from 'react'
import * as S from './styles'
import { AlertProps } from './types'
import { Button } from '../Button'

export const Alert: FC<AlertProps> = memo(
  ({
    size = 'medium',
    close,
    action,
    children,
    isLoading = false,
    isDisabled = false,
    cancelButtonName = 'Cancel',
    confirmButtonName = 'Confirm',
    overflow = true,
    noCancelButton = false,
    secondButtonName,
    closeSecondButton
  }) => {
    // Use state to store the body reference, but only set it in useEffect
    const [body, setBody] = useState<HTMLBodyElement | null>(null)

    useEffect(() => {
      if (typeof document !== 'undefined') {
        setBody(document.querySelector('body'))
      }
    }, [])

    const handleKeyUp = useCallback(
      (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          e.preventDefault()
          close()
          window.removeEventListener('keyup', handleKeyUp, false)
        }
      },
      [close]
    )

    useEffect(() => {
      if (overflow && body) {
        window.addEventListener('keyup', handleKeyUp, false)
        body.style.overflow = 'hidden'

        return () => {
          window.removeEventListener('keyup', handleKeyUp, false)
          body.style.overflow = 'visible'
        }
      }
    }, [handleKeyUp, body, overflow])

    return (
      <S.Background className='alert-background'>
        <S.Content size={size} className='alert-content'>
          <S.InnerContent>{children}</S.InnerContent>

          <S.Actions>
            <Button kind='warning' onClick={close}>
              {confirmButtonName}
            </Button>
            {secondButtonName && (
              <Button kind='dark' onClick={closeSecondButton}>
                {secondButtonName}
              </Button>
            )}

            {!noCancelButton && (
              <Button
                kind='weak'
                isOutlined
                isLoading={isLoading}
                isDisabled={isDisabled || isLoading}
                onClick={action}
              >
                {cancelButtonName}
              </Button>
            )}
          </S.Actions>
        </S.Content>
      </S.Background>
    )
  }
)

Alert.displayName = 'Alert'

import { IMaterial } from '@/client/types'
import { api } from '@/client/utils'

/**
 *
 *  Retrieves material details asynchronously by its ID when triggered by a button click.
 *  @param {number} id - The ID of the material to retrieve.
 *  @returns {Promise<IMaterial>} - A promise resolving to the material data.
 */

export const getMaterial = async (id: number, locale: string) => {
  const url = `/material/details/${id}/${locale}`
  const { data } = await api.get<IMaterial>(url)
  return data
}

export const getPlusMaterial = async () => {
  const url = `/material/plusMaterial`
  const { data } = await api.get(url)
  return data
}

export const getStandardMaterial = async () => {
  const url = '/material/standardMaterial'
  const { data } = await api.get(url)
  return data
}

export const getSustainableMaterial = async () => {
  const url = '/material/sustainableMaterial'
  const { data } = await api.get(url)
  return data
}

import { useQuery, useMutation, useQueryClient, useIsMutating } from '@tanstack/react-query'
import { parseCookies } from 'nookies'
import { StorageKey, Cookies, ICartSelection } from '@/client/types'
import {
  createFolder,
  deleteFolder,
  getFolderEntries,
  getTabledata,
  moveToDownload,
  moveToFolder,
  renameFolder,
  getCustomTabledata,
  getPreview,
  deleteFile,
  getJobdata,
  addToFav
} from '@/infra/services/labelLibraryService'
import { IMutateFolder, SubSort } from './types'
import { useGetUserInfos } from '../userInfos'
import { getPageNo } from '@/client/components/MultiTable/helpers'

/**
 *
 * Generate sub items for each table.
 * @returns data and subSorts array
 *
 */
export const generateSubItems = (currentItems, keyword) => {
  console.log(currentItems, 'currentItems')
  let isOrder = false
  if (!currentItems) return undefined
  if (keyword.includes('jobs')) {
    isOrder = true
  }
  const currentItemsData = currentItems['data']
  const subSorts: SubSort[] = []
  for (let i = 0; i < currentItemsData.length; i++) {
    const { id, number_of_pages } = currentItemsData[i]
    subSorts.push({
      id: id,
      subSorts: number_of_pages,
      checkedSorts: new Array(number_of_pages).fill(isOrder ? false : true)
    })
  }
  currentItems['subSorts'] = subSorts
  // Filter results with keyword
  const filteredResult = currentItems?.data?.filter((e) => e['filename'].toLowerCase().includes(''))
  currentItems['filteredResults'] = currentItems?.data
  return currentItems
}
/**
 *
 * Generate folderITems.
 *
 */
export const generateFolderItems = (folderData) => {
  const folderSelectorMutation = folderData && [
    ...folderData.map((e) => {
      return {
        name: e['folder_name'],
        id: e['id']
      }
    })
  ]
  folderData['options'] = folderSelectorMutation
  return folderData
}

/**
 *
 * @returns Get folder entries for label library (especially for custom folders)
 */
export const useGetFolderEntries = (fetchStatus) => {
  const cookies = parseCookies()
  const cartId = cookies[Cookies.CART_ID]

  const { data, isLoading, isFetching, refetch, isRefetching, remove, isSuccess, status } = useQuery({
    queryKey: [StorageKey.LABEL_LIBRARY_FOLDERS],
    queryFn: () => getFolderEntries(),
    select: (data) => generateFolderItems(data),
    enabled: fetchStatus,
    refetchOnWindowFocus: false
  })

  return {
    isLoading,
    isFetching,
    refetch,
    isRefetching,
    isSuccess,
    remove,
    status,
    data: data || undefined
  }
}

/**
 *
 * @returns Table data for each page inside label library.
 */

export const useGetTableData = ({ filterString }) => {
  console.log(filterString, 'filterString')
  const cookies = parseCookies()
  const { data, isLoading, isFetching, refetch, isRefetching, remove, isSuccess, status } = useQuery({
    queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA, filterString],
    queryFn: () => getTabledata(filterString),
    enabled: true,
    refetchOnWindowFocus: false,
    select: (data) => generateSubItems(data, filterString)
  })
  return {
    isLoading,
    isFetching,
    refetch,
    isRefetching,
    isSuccess,
    status,
    remove,
    data: data
  }
}
/**
 *
 * @returns Table data for each page inside label library.
 */

export const useGetCustomTableData = ({ filterString }) => {
  const cookies = parseCookies()

  const { data, isLoading, isFetching, refetch, isRefetching, remove, isSuccess, status } = useQuery({
    queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA, filterString],
    queryFn: () => getCustomTabledata(filterString),
    enabled: true,
    refetchOnWindowFocus: false,
    select: (data) => generateSubItems(data, '')
  })
  return {
    isLoading,
    isFetching,
    refetch,
    isRefetching,
    remove,
    isSuccess,
    status,
    data: data
  }
}
/**
 *
 * @returns Table data for each page inside label library.
 */

export const useGetJobData = ({ filterString }) => {
  const cookies = parseCookies()
  const { data, isLoading, isFetching, refetch, isRefetching, remove, isSuccess, status } = useQuery({
    queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA, filterString],
    queryFn: () => getJobdata(filterString),
    enabled: true,
    refetchOnWindowFocus: false
  })
  return {
    isLoading,
    isFetching,
    refetch,
    isRefetching,
    remove,
    isSuccess,
    status,
    data: data
  }
}

/**
 *
 * @returns Create folder.
 *
 */

export const useMutateFolder = () => {
  const queryClient = useQueryClient()
  const isLoading = useIsMutating([StorageKey.LABEL_LIBRARY_FOLDER_UPDATE])
  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.LABEL_LIBRARY_FOLDER_UPDATE],
    mutationFn: ({ payload, customer_id }: IMutateFolder) => createFolder(payload, customer_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_FOLDERS] })
    }
  })
  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}

/**
 *
 * @returns Delete folder.
 *
 */

export const useMutateDeleteFolder = () => {
  const queryClient = useQueryClient()
  const isLoading = useIsMutating([StorageKey.LABEL_LIBRARY_FOLDER_UPDATE])
  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.LABEL_LIBRARY_FOLDER_DELETE],
    mutationFn: ({ customer_id, id }: any) => deleteFolder(customer_id, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_FOLDERS] })
    }
  })
  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}

/**
 *
 * @returns Create folder.
 *
 */

export const useMutateDeleteFile = () => {
  const queryClient = useQueryClient()
  const isLoading = useIsMutating([StorageKey.LABEL_LIBRARY_FOLDER_UPDATE])
  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.LABEL_LIBRARY_FOLDER_DELETE],
    mutationFn: ({ customer_id, folderIdid, fileId, payload }: any) =>
      deleteFile(customer_id, folderIdid, fileId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_FOLDERS] })
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA] })
    }
  })
  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}

/**
 *
 * @returns Rename folder.
 *
 */

export const useMutateRenameFolder = () => {
  const queryClient = useQueryClient()
  const isLoading = useIsMutating([StorageKey.LABEL_LIBRARY_FOLDER_UPDATE])
  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.LABEL_LIBRARY_FOLDER_DELETE],
    mutationFn: ({ customer_id, id, payload }: any) => renameFolder(customer_id, id, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_FOLDERS] })
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA] })
    }
  })
  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}
export const useMutateMovetoFolder = () => {
  const queryClient = useQueryClient()
  const isLoading = useIsMutating([StorageKey.LABEL_LIBRARY_FOLDER_UPDATE])
  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.LABEL_LIBRARY_FOLDER_DELETE],
    mutationFn: ({ folderId, fileId, payload }: any) => moveToFolder(folderId, fileId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_FOLDERS] })
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA] })
    }
  })
  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}

/***
 *
 * Download a file
 *
 */
export const useDownloadFolder = () => {
  const queryClient = useQueryClient()
  const isLoading = useIsMutating([StorageKey.LABEL_LIBRARY_FOLDER_UPDATE])
  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.LABEL_LIBRARY_FOLDER_DELETE],
    mutationFn: ({ customer_id, folderId, fileId, currentPage, payload }: any) =>
      moveToDownload(folderId, fileId, currentPage),
    onSuccess: () => {}
  })
  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}
export const useClearQuery = () => {
  const queryClient = useQueryClient()
  const { mutate } = useMutation({
    mutationKey: ['CLEAR_KEY'],
    mutationFn: (): any => {
      queryClient.removeQueries({ queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA] })
      // queryClient.resetQueries({ queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA], exact: true })
    },
    onSuccess: () => {}
  })
  return {
    mutate
  }
}

export const useGetPreview = ({ filterString }) => {
  const queryClient = useQueryClient()

  const { data, isLoading, isFetching, refetch, isRefetching, remove, isSuccess, status } = useQuery({
    queryKey: [StorageKey.LABEL_LIBRARY_FOLDERS],
    queryFn: () => getJobdata(filterString),
    enabled: true,
    refetchOnWindowFocus: false
  })

  return {
    data,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}

/**
 *
 * @returns Add to favourites.
 *
 */

export const useMutateAddToFav = () => {
  const queryClient = useQueryClient()
  const isLoading = useIsMutating([StorageKey.LABEL_LIBRARY_TABLE_DATA])
  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA],
    mutationFn: ({ id, payload }: any) => addToFav(id, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA] })
    }
  })
  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}

import { FC, memo, useEffect, useState, useCallback, useContext } from 'react'
import { IconSizes } from '@/client/components/Icon/types'
import { Row, Col, Breadcrumb, Checkbox, Button, Link, ProxyImage } from '@/client/components'
import * as S from './styles'
import { Icon } from '@/client/components/Icon'
import { Text } from '@/client/components/Text'
import { MultiTableProps, SubSortProps } from './types'
import {
  useDownloadFolder,
  useGetFolderEntries,
  useGetRecentOrders,
  useGetTableData,
  useGetUserInfos,
  useMutateAddToFav,
  useRouterPush
} from '@/client/hooks'
import { formatDate } from '@/client/utils/formatDate'
import router from 'next/router'
import { LabelLibraryContext } from '@/client/context/labelLibrary'
import { Controls } from './Controls'
import { getImageLinkString, getTableContent } from '../helpers'
import { ACTION_BUTTON, BUTTON_GROUP, COMMON_FIELD_DATA, COMMON_FIELDS, MAIN_TABLE, TABLE_CONTENT } from '../types'
import { Content } from 'next/font/google'
import { refineValueFromURL } from '@/client/utils/labelLibrary'
import { DetailRow } from '@/client/modules/Cart/PaymentStep/styles'
import { Details } from './Details'
const isObjectEmpty = (obj: { [key: string]: string }) => Object.keys(obj).length === 0 && obj.constructor === Object

const iconCloseSize: Record<string, IconSizes> = {
  small: 's3',
  medium: 's5',
  large: 's6',
  full: 's7'
}

export const FavouriteTable: FC<any> = memo(({ searchItem = '', content }) => {
  const { asPath, routerPush } = useRouterPush()
  const { getCurrentPage, searchParam } = refineValueFromURL(asPath)
  const [eachItem, setEachItem] = useState<string[]>([])
  const [showDropdown, setShowDropdown] = useState(null)
  const [eachSortItem, setEachSortItem] = useState<any>([])
  const [folderSelector, setFolderSelector] = useState([{ id: 1, name: '' }])
  const [activePreview, setActivePreview] = useState<number | null>(null)
  const [subActivePreview, setSubActivePreview] = useState<number | null>(null)

  /**
   * Dynamically accesses CMS.
   * The property names are stored in constants,
   * allowing for dynamic and flexible property access.
   *
   * @param {Object} content - The object containing the cms content data.
   * @param {string} TABLE_CONTENT - Child elements of cms content.
   * @param {string} MAIN_TABLE - Child elements of cms content.
   * @returns {any} cms_content - The content.
   */
  const {
    [TABLE_CONTENT]: { [MAIN_TABLE]: cms_content },
    [BUTTON_GROUP]: { [ACTION_BUTTON]: button_group_data },
    [COMMON_FIELDS]: { [COMMON_FIELD_DATA]: common_field_data }
  } = content

  /**
   *
   * Context for Label Library
   *
   */
  const {
    addToCopySelection,
    totalPage,
    perPage,
    setTotalPage,
    setPerPage,
    setLoader,
    setMessages,
    setJobId,
    searchKeyword,
    setCurrentSort,
    currentSort,
    currentFolder,
    currentPage
  } = useContext(LabelLibraryContext)
  const { mutate: addToFav } = useMutateAddToFav()
  const { mutate: moveToDownload } = useDownloadFolder()
  const {
    data: tableData,
    isLoading,
    isSuccess: customTableDataReady,
    status: customTableLoadingStatus,
    refetch
  } = useGetTableData({
    filterString: searchParam == null ? `favorite=1&page=${getCurrentPage}` : `favorite=1&search=${searchParam}`
  })

  const [refinedResults, setRefinedResults] = useState([])

  const selectRow = (elemId, filename, job_id) => {
    setCurrentSort([0])
    setEachItem((prevItems) => [
      ...prevItems.slice(1), // Remove the first item
      tableData['subSorts'].find((e) => e['id'] === elemId) // Add the new item to the end
    ])
    addToCopySelection({
      filename: filename,
      customerFileId: elemId
    })
    setJobId(job_id)
  }

  const selectSubSortsRow = (e, value) => {
    e.stopPropagation()
    // const getSort =
    //   currentSort && currentSort.length > 0 && currentSort.includes(e.target.value)
    //     ? currentSort.filter((i) => i !== e.target.value)
    //     : [...currentSort, e.target.value]
    // console.log(
    //   currentSort,
    //   'index',
    //   currentSort.includes(e.target.value)
    //     ? currentSort.filter((i) => i !== e.target.value)
    //     : [...currentSort, e.target.value]
    // )

    const getSorts = currentSort.includes(value) ? currentSort.filter((i) => i !== value) : [...currentSort, value]

    setCurrentSort(getSorts)
    // setCurrentSort((prevItems) => (prevItems.includes(e.target.value) ? [prevItems] : [...prevItems, e.target.value]))
    // setCurrentSort(getSort)
    // console.log('after', currentSort)
    // // setEachSortItem((prevItems) =>
    //   prevItems.map((item) => {
    //     if (item.id === elem.id) {
    //       const isPresent = item.subSorts.includes(subSortId)
    //       const updatedSubSorts = isPresent
    //         ? item.subSorts.filter((id) => id !== subSortId)
    //         : [...item.subSorts, subSortId]

    //       return {
    //         ...item,
    //         subSorts: updatedSubSorts
    //       }
    //     }
    //     return item
    //   })
    // )
  }

  // useEffect(() => {
  //   setCurrentPage(page)
  // }, [])

  useEffect(() => {
    setLoader(true)
    setCurrentSort([0, '1'])
    if (tableData && tableData.data.length == 0) {
      setMessages('Sorry, There is no data associated with this folder')
    } else {
      setMessages('')
    }

    if (customTableDataReady && tableData) {
      setTotalPage(tableData['total'])
      setPerPage(tableData['per_page'])
      setLoader(false)
    }
  }, [tableData, customTableDataReady, customTableLoadingStatus, asPath])

  const goNext = () => {
    const getNextPage = totalPage === getCurrentPage ? getCurrentPage : getCurrentPage + 1
    const refinedPath = asPath.split('?')[0]
    router.push(
      {
        pathname: refinedPath,
        query: { page: getNextPage }
      },
      undefined,
      { shallow: true }
    )
  }

  const goPrevious = () => {
    const previousPage = getCurrentPage === 1 ? getCurrentPage : getCurrentPage - 1
    const refinedPath = asPath.split('?')[0]
    router.push(
      {
        pathname: refinedPath,
        query: { page: previousPage }
      },
      undefined,
      { shallow: true }
    )
  }

  // Show preivew for parent elements
  const showPreview = (index: number) => {
    if (activePreview === index) {
      // If the same image is clicked again, close the popup
      setActivePreview(null)
      setSubActivePreview(null)
    } else {
      setSubActivePreview(null)
      setActivePreview(index)
    }
  }

  // Show preivew for sub elements
  const showSubPreview = (index: number) => {
    if (subActivePreview === index) {
      // If the same image is clicked again, close the popup
      setActivePreview(null)
      setSubActivePreview(null)
    } else {
      setActivePreview(null)
      setSubActivePreview(index)
    }
  }

  // Handles outside click to hide preview components
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement | null

      if (activePreview !== null && target && !target.closest('.popup')) {
        setActivePreview(null)
        setSubActivePreview(null)
      }
      if (subActivePreview !== null && target && !target.closest('.popup')) {
        setActivePreview(null)
        setSubActivePreview(null)
      }
    }

    // Adding event listener to the document
    document.addEventListener('click', handleClickOutside)

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [activePreview, subActivePreview])

  const getMoreDeatils = (elm) => {}

  // Favourites function
  const handleFavourites = async (id) => {
    if (id)
      await addToFav(
        {
          id: id,
          payload: {
            is_favorite: false
          }
        },
        {
          onSuccess: () => {}
        }
      )
  }

  /**
   * Download a file.
   * */
  const downloadAction = async (id) => {
    const getDownloadResponse = await moveToDownload(
      {
        folderId: currentFolder,
        fileId: id,
        currentPage: currentPage
      },
      {
        onSuccess: (res) => {
          window.open(res.download_url, '_blank')
        }
      }
    )
  }

  const redirectToReorder = (jobId, id) => {
    setLoader(true)
    routerPush(`/my-account/label-library/reorder?job_id=${jobId}&file_id=${id}&sort=${currentSort.length - 1}`)
  }

  return (
    <S.MultitableWrap>
      <>
        <Row className='multitable-row' middle='xs' isFull>
          <Col xs='12' className='mutile-table-outer-row-header' noGutter>
            <Row isFull className='mutile-table-inner-row mutile-table-inner-row-header' middle='xs'>
              <Col xs='5' xl2='4' noGutter>
                <Row isFull middle='xs'>
                  <Col xs='1' xl2='1' className='multitable-select-field'></Col>
                  <Col xs='2' xl2='3'>
                    <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                      {getTableContent(cms_content, 'preview', 'table_cell_name')}
                    </Text>
                  </Col>
                  <Col xs='9' xl2='8'>
                    <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                      {getTableContent(cms_content, 'filename', 'table_cell_name')}
                    </Text>
                  </Col>
                </Row>
              </Col>

              <Col xs='2' xl2='1' className='sorts-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent(cms_content, 'sorts', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='2' xl2='2' className='size-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent(cms_content, 'size', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='2' xl2='2' className='createdate-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent(cms_content, 'create_date', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='1' xl2='1' className='lastorder-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent(cms_content, 'last_order_no', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='1' xl2='2' className='lastorder-col'></Col>
            </Row>
          </Col>
        </Row>
      </>

      {tableData?.filteredResults.map((elm, i) => (
        <S.multiTableRow
          key={`table-data-myorder-${i}`}
          isRowChecked={eachItem.length > 0 && eachItem[0]['id'] === elm['id']}
        >
          <Row
            className='multitable-row'
            middle='xs'
            isFull
            onClick={() => selectRow(elm['id'], elm['filename'], elm['job_id'])}
          >
            <Col xs='12' noGutter>
              <Row isFull className='mutile-table-inner-row' middle='xs'>
                <Col xs='5' xl2='4' noGutter>
                  <Row isFull middle='xs'>
                    <Col xs='1' className='arrow-checkbox'>
                      <Icon name='chevron-down' size='s3' />
                      <Checkbox
                        key={elm['id']}
                        id={elm['id']}
                        value={elm['id']}
                        name={elm['id']}
                        label={''}
                        description={''}
                        checked={eachItem.length > 0 && eachItem[0]['id'] === elm['id']}
                        onChange={() => selectRow(elm['id'], elm['filename'], elm['job_id'])}
                        kind='circle'
                      />
                    </Col>
                    <Col xs='2' xl2='3' style={{ position: 'relative' }}>
                      <S.ImageBlock>
                        {elm['preview_link'] || elm['preview_link'] !== '' ? (
                          <ProxyImage
                            src={getImageLinkString(`customerFile/${elm['id']}`, '', elm['id'])}
                            page={getCurrentPage}
                            width={127}
                            height={92}
                            alt={'Preview image'}
                            title={'Preview Image'}
                            index={i}
                            showPreview={showPreview}
                            isOpen={activePreview === i}
                          />
                        ) : (
                          <img src='/img/my-account/no-image.png' alt='Preview no image' style={{ maxWidth: '100%' }} />
                        )}
                      </S.ImageBlock>
                    </Col>
                    <Col xs='9' xl2='8'>
                      <S.WrapText>
                        <Text family='text' color='weak' size='sm' style={{ margin: '0' }} title={elm['filename']}>
                          {elm['filename']}
                        </Text>
                      </S.WrapText>
                    </Col>
                  </Row>
                </Col>

                <Col xs='2' xl2='1' className='sorts-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['number_of_pages']}
                  </Text>
                </Col>
                <Col xs='2' xl2='2' className='size-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['width']} X {elm['height']}
                  </Text>
                </Col>
                <Col xs='2' xl2='2' className='createdate-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {formatDate(elm['created_at'])}
                  </Text>
                </Col>
                <Col xs='1' xl2='1' className='lastorder-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['last_order_number']}
                  </Text>
                </Col>
                <Col xs='1' xl2='2' className='lastorder-col'>
                  <S.ControlCenter>
                    <S.EachControl>
                      <Button
                        kind='blank'
                        title={getTableContent(common_field_data, 'download_label', 'label')}
                        onClick={() => {
                          downloadAction(elm['id'])
                        }}
                      >
                        <Icon name='download-fade'></Icon>
                      </Button>
                    </S.EachControl>
                    <S.EachControl>
                      <Button
                        kind='blank'
                        title={getTableContent(common_field_data, 're_order_label', 'label')}
                        onClick={(e) => {
                          e.stopPropagation()
                          redirectToReorder(elm['job_id'], elm['id'])
                        }}
                      >
                        <Icon name='reorder'></Icon>
                      </Button>
                    </S.EachControl>
                    <S.EachControl>
                      {elm['is_favorite'] ? (
                        <Button
                          kind='blank'
                          title={getTableContent(common_field_data, 'remove_fav', 'label')}
                          onClick={() => handleFavourites(elm['id'])}
                        >
                          <Icon name='fav-selected'></Icon>
                        </Button>
                      ) : (
                        <Button
                          kind='blank'
                          title={getTableContent(common_field_data, 'add_to_fav_label', 'label')}
                          onClick={() => handleFavourites(elm['id'])}
                        >
                          <Icon name='fav'></Icon>
                        </Button>
                      )}
                    </S.EachControl>
                  </S.ControlCenter>
                </Col>
              </Row>
              {eachItem.length > 0 &&
                eachItem
                  .filter((e) => e['id'] === elm['id'])
                  .map((subSort, subSortIndex) => (
                    <>
                      {Array.from({ length: subSort['subSorts'] }, (_, index) => (
                        <Row
                          isFull
                          middle='xs'
                          className='multiple-select-field-sorts'
                          onClick={(el) => {
                            selectSubSortsRow(el, String(index + 1))
                          }}
                        >
                          <Col xs='5' xl2='4'>
                            <Row isFull middle='xs'>
                              <Col xs='2' noGutter>
                                <Checkbox
                                  key={subSort['id'] + index}
                                  id={subSort['id'] + String(index) + 'subsort'}
                                  value={String(index + 1)}
                                  name={subSort['id']}
                                  label={''}
                                  description={''}
                                  onChange={(e) => {
                                    e?.stopPropagation()
                                  }}
                                  kind='circle'
                                  checked={currentSort.includes(String(index + 1))}
                                />
                              </Col>
                              <Col xs='2' xl2='3' style={{ position: 'relative' }}>
                                <S.ImageBlock>
                                  {elm['preview_link'] || elm['preview_link'] !== '' ? (
                                    <ProxyImage
                                      src={getImageLinkString(`customerFile/${elm['id']}`, index + 1, elm['id'])}
                                      page={getCurrentPage}
                                      width={127}
                                      height={92}
                                      alt={'Preview image'}
                                      title={'Preview Image'}
                                      index={index}
                                      showPreview={showSubPreview}
                                      isOpen={subActivePreview === index}
                                    />
                                  ) : (
                                    <img
                                      src='/img/my-account/no-image.png'
                                      alt='Preview no image'
                                      style={{ maxWidth: '100%' }}
                                    />
                                  )}
                                </S.ImageBlock>
                              </Col>
                              <Col xs='9' xl2='7'>
                                <S.WrapText>
                                  <Text
                                    family='text'
                                    color='weak'
                                    size='sm'
                                    style={{ margin: '0' }}
                                    title={elm['filename']}
                                  >
                                    {elm['filename']}
                                  </Text>
                                </S.WrapText>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs='2' xl2='2' className='sorts-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {index + 1}/{elm['number_of_pages']}
                            </Text>
                          </Col>
                          <Col xs='2' xl2='2' className='size-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {elm['width']} X {elm['height']}
                            </Text>
                          </Col>
                          <Col xs='2' xl2='2' className='createdate-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {formatDate(elm['created_at'])}
                            </Text>
                          </Col>
                          <Col xs='2' xl2='2' className='lastorder-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {elm['last_order_number']}
                            </Text>
                          </Col>
                        </Row>
                      ))}
                    </>
                  ))}
            </Col>
            {/* <Col xs='12' noGutter>
              <Button kind='blank' size='small' onClick={() => getMoreDeatils(elm)}>
                More Details<Icon name='chevron-down' size='s1'></Icon>
              </Button>
              {eachItem.length > 0 && eachItem[0]['id'] === elm['id'] && <Details job={elm['job_id']} />}
            </Col> */}
          </Row>
        </S.multiTableRow>
      ))}
      {tableData && (
        <Controls
          currentPage={getCurrentPage}
          goPrevious={goPrevious}
          goNext={goNext}
          totalPage={totalPage}
          perPage={perPage}
          content={button_group_data}
        />
      )}
    </S.MultitableWrap>
  )
})

FavouriteTable.displayName = 'FavouriteTable'

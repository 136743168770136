export enum StorageKey {
  USER_ADDRESS = 'user-address',
  USER_INFOS = 'user-infos',
  BRANCHES = 'branches',
  HISTORIC_CART = 'historic-cart',
  CHECKOUT_CART = 'checkout-cart',
  QUOTE_ITEM = 'quote-item',
  CALCULATOR_INFO = 'calculator-infos',
  PACKAGES_INFO = 'general-packages',
  PRICES = 'prices',
  MUTATE_PRICES = 'mutate-prices',
  GET_CART = 'get-cart',
  SEARCH = 'search',
  ORDERS = 'orders',
  ARTWORK_SERVICES = 'artwork-services',
  ARTWORK_ASSISTANT = 'artwork-assistant',
  DESIGN_SERVICES = 'design-services',
  DESIGN_SERVICES_DATA = 'design-services-data',
  DESIGN_SERVICES_MUTATION = 'design-services-mutation',
  STATES = 'states',
  SAMPLE_BOOK = 'sample-book',
  CONTACT = 'contact',
  MATERIAL = 'material',
  LOCATIONS = 'locations',
  LABEL_LIBRARY_FOLDERS = 'label-library-table-data',
  LABEL_LIBRARY_TABLE_DATA = 'label-library-folders',
  LABEL_LIBRARY_FOLDER_UPDATE = 'label-library-folder-update',
  LABEL_LIBRARY_FOLDER_DELETE = 'label-library-folder-delete',
  TRANSPARENCY_CALCULATOR_INFO = 'transparency-calculator-info',
  RECENT_ORDERS = 'recent-orders',
  TRANSPARENCY_BRANDS = 'transparency_brands',
  PREVIEW_DATA = 'preview-data',
  PLUS_MATERIAL = 'plus-material',
  STANDARD_MATERIAL = 'standard-material',
  SUSTAINABLE_MATERIAL = 'sustainable_material'
}
